.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
body {
  cursor: url('../public/Images/cursor.png'), auto;
  scroll-behavior: smooth;
}
html{
  cursor: url('../public/Images/cursor.png'), auto;
  scroll-behavior: smooth;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@font-face {
  font-family: Constan;
  src: url("./assets/fonts/constan.ttf");
}
@font-face {
  font-family: PoppinsR;
  src: url("./assets/fonts/Poppins-Regular.ttf");
}
@font-face {
  font-family: Pulang;
  src: url("./assets/fonts/Pulang.ttf");
}

.background-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Place the video behind other content */
}

/* Ensure the video takes up the entire container */
.background-video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}